import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Header from "../components/header"
import Seo from "../components/seo"

import * as Styles from "./styles/index.module.scss"

const IndexPage = () => {
  const DATA = useStaticQuery(graphql`
    query RootSiteTitleQuery {
      site {
        siteMetadata {
          title,
          divisionJa { gyosei, support }
        }
      }
    }
  `)

  return (
    <div className="w-screen">
      <Header siteTitle={DATA.site.siteMetaData?.title || `Title`} division="" />
      <Seo title="白玉" />
      <main>
        <div className="flex flex-col w-screen sm:flex-row">
          <div className={`${Styles.section} bg-gyosei_color`}>
            <div className={Styles.contents}>
              <p className={Styles.description}>
                  会計記帳、婚前契約書・遺言書・各種書類の作成<br />
                  事業復活支援金の事前確認については<br />
                  こちらのボタンを押してください
              </p>
              <Link to="/gyosei/">
                <StaticImage
                  src="../images/common/circle_gyosei.png"
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt={DATA.site.siteMetadata.divisionJa.gyosei}
                  className={Styles.logo}
                />
              </Link>
            </div>
          </div>

          <div className={`${Styles.section} bg-support_color`}>
            <div className={Styles.contents}>
              <p className={Styles.description}>
                  IT導入支援、webサイト作成、事務代行<br />
                  その他各種事業サポートについては<br />
                  こちらのボタンを押してください
              </p>
              <Link to="/support/">
                <StaticImage
                  src="../images/common/circle_support.png"
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt={DATA.site.siteMetadata.divisionJa.support}
                  className={Styles.logo}
                />
              </Link>
            </div>
          </div>
        </div>
      </main>
    </div>
  )
}

export default IndexPage
